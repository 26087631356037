<template>
    <el-dialog title="" :visible.sync="visible" width="900px" :before-close="handleClose">
        <div>
            <div class="checkin">
                <div class="right_pannel">
                    <div class="info-panel" style="width:800px;margin:auto">
                        <div class="booked-ticket">
                            <div class="booked-title">
                                <div class="title">行程信息</div>
                            </div>
                            <div class="booked-ticket-body">
                                <div class="passenger-info no-padding">
                                    <div
                                        v-for="segment in order.segments"
                                        :key="segment.segmentRef"
                                        class="refund-segment"
                                    >
                                        <fee-panel :segments="order.segments"/>
                                        <el-checkbox
                                            @change="segAndPassChange"
                                            v-model="segment.checked"
                                            class="refund-segment-check"
                                        ></el-checkbox>
                                    </div>
                                    <!-- <el-form
                                                class="passenger-input-info"
                                                style="margin-top:10px;"
                                                label-position="top"
                                            >
                                                <el-row
                                                    v-for="segment in order.segments"
                                                    v-bind:key="segment.segmentRef"
                                                >
                                                    <el-col :span="1">
                                                        <el-form-item>
                                                            <template slot="label">
                                                                <div class="form-label">
                                                                    <el-checkbox
                                                                        @change="segAndPassChange"
                                                                        v-model="segment.checked"
                                                                    ></el-checkbox>
                                                                </div>
                                                            </template>
                                                        </el-form-item>
                                                    </el-col>
                                                </el-row>
                                    </el-form>-->
                                </div>
                            </div>
                        </div>
                        <div class="booked-ticket">
                            <div class="booked-title">
                                <div class="title">乘机人信息</div>
                            </div>
                            <div class="booked-ticket-body">
                                <div class="passenger-info no-padding">
                                    <el-form class="passenger-input-info" label-position="top">
                                        <div
                                            class="order-pass-item"
                                            v-for="(pass, idx) in order.passengers"
                                            v-bind:key="pass.certNo"
                                        >
                                            <div>
                                                <div class="item-index">{{idx + 1}}</div>
                                                <div>
                                                    <span class="item-title">{{pass.type}}</span>
                                                    <span class="item-value">{{pass.name}}</span>
                                                </div>
                                                <div>
                                                    <span class="item-title">{{pass.certType}}</span>
                                                    <span class="item-value">{{pass.certNo}}</span>
                                                </div>
                                                <div>
                                                    <span class="item-title">手机号</span>
                                                    <span
                                                        class="item-value"
                                                    >{{order.contact.mobile}}</span>
                                                </div>
                                                <div>
                                                    <span class="item-title">票号</span>
                                                    <span class="item-value">{{order.orderNo}}</span>
                                                </div>
                                            </div>
                                            <div class="form-label">
                                                <el-checkbox
                                                    class="order-pass-item-check"
                                                    @change="segAndPassChange"
                                                    v-model="pass.checked"
                                                    v-if="pass.tickets[0].ticketStatus=='OPENFORUSE'"
                                                ></el-checkbox>
                                                <el-checkbox
                                                    class="order-pass-item-check"
                                                    @change="segAndPassChange"
                                                    disabled
                                                    v-model="pass.checked"
                                                    v-if="pass.tickets[0].ticketStatus!='OPENFORUSE'"
                                                ></el-checkbox>
                                            </div>
                                        </div>
                                    </el-form>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="booked-ticket">
                                    <div class="booked-title">
                                        <div class="title">旅客信息</div>
                                    </div>
                                    <div class="booked-ticket-body">
                                        <div class="passenger-info no-padding">
                                            <el-form
                                                class="passenger-input-info"
                                                label-position="top"
                                            >
                                                <el-row>
                                                    <el-col :span="1">
                                                        <el-form-item>
                                                            <template slot="label"></template>
                                                        </el-form-item>
                                                    </el-col>
                                                    <el-col :span="5">
                                                        <el-form-item>
                                                            <template slot="label">
                                                                <div class="form-label">姓名</div>
                                                            </template>
                                                        </el-form-item>
                                                    </el-col>
                                                    <el-col :span="7">
                                                        <el-form-item>
                                                            <template slot="label">
                                                                <div class="form-label">证件号</div>
                                                            </template>
                                                        </el-form-item>
                                                    </el-col>
                                                    <el-col :span="5">
                                                        <el-form-item>
                                                            <template slot="label">
                                                                <div class="form-label">票号</div>
                                                            </template>
                                                        </el-form-item>
                                                    </el-col>
                                                    <el-col :span="6">
                                                        <el-form-item>
                                                            <template slot="label">
                                                                <div class="form-label">状态</div>
                                                            </template>
                                                        </el-form-item>
                                                    </el-col>
                                                </el-row>
                                                <el-row
                                                    v-for="pass in order.passengers"
                                                    v-bind:key="pass.certNo"
                                                >
                                                    <el-col :span="1">
                                                        <el-form-item>
                                                            <template slot="label">
                                                                <div class="form-label">
                                                                    <el-checkbox
                                                                        @change="segAndPassChange"
                                                                        v-model="pass.checked"
                                                                        v-if="pass.tickets[0].ticketStatus=='OPENFORUSE'"
                                                                    ></el-checkbox>
                                                                    <el-checkbox
                                                                        @change="segAndPassChange"
                                                                        disabled
                                                                        v-model="pass.checked"
                                                                        v-if="pass.tickets[0].ticketStatus!='OPENFORUSE'"
                                                                    ></el-checkbox>
                                                                </div>
                                                            </template>
                                                        </el-form-item>
                                                    </el-col>
                                                    <el-col :span="5">
                                                        <el-form-item>
                                                            <template slot="label">
                                                                <div
                                                                    class="form-label"
                                                                >{{pass.name}}({{pass.type}})</div>
                                                            </template>
                                                        </el-form-item>
                                                    </el-col>
                                                    <el-col :span="7">
                                                        <el-form-item>
                                                            <template slot="label">
                                                                <div
                                                                    class="form-label"
                                                                >{{pass.certNo}}({{pass.certType}})</div>
                                                            </template>
                                                        </el-form-item>
                                                    </el-col>
                                                    <el-col :span="5">
                                                        <el-form-item>
                                                            <template slot="label">
                                                                <div
                                                                    class="form-label"
                                                                >{{pass.tickets[0].ticketNo}}</div>
                                                            </template>
                                                        </el-form-item>
                                                    </el-col>
                                                    <el-col :span="6">
                                                        <el-form-item>
                                                            <template slot="label">
                                                                <div
                                                                    class="form-label"
                                                                >{{pass.tickets[0].actualStatus | formatActualStatus}}</div>
                                                            </template>
                                                        </el-form-item>
                                                    </el-col>
                                                </el-row>
                                            </el-form>
                                        </div>
                                    </div>
                        </div>-->

                        <div class="booked-ticket">
                            <div class="booked-title">
                                <div class="title">
                                    退票类型
                                    <span style="padding-left: 10px;">
                                        <el-radio-group
                                            v-model="refundFeeRequest.refundType"
                                            @change="segAndPassChange"
                                        >
                                            <el-radio label="UNCONSTRAINT">自愿退</el-radio>
                                            <el-radio label="CONSTRAINT">非自愿退</el-radio>
                                        </el-radio-group>
                                    </span>
                                </div>
                            </div>
                            <div class="booked-ticket-body">
                                <div class="passenger-info no-padding">
                                    <el-form class="passenger-input-info" label-position="top">
                                        <el-input
                                            type="textarea"
                                            :rows="2"
                                            placeholder="请输入退票原因"
                                            v-model="refundFeeRequest.refundReason"
                                        ></el-input>
                                    </el-form>
                                </div>
                            </div>
                        </div>
                        <div class="booked-ticket">
                            <div class="booked-title">
                                <div class="title">退票详情</div>
                            </div>
                            <div class="booked-ticket-body">
                                <div class="passenger-info no-padding">
                                    <div>
                                        <span class="item-title">预计退款</span>
                                        <span class="item-value">¥{{applyRefundAmount}}</span>
                                    </div>
                                    <div style="display: flex">
                                        <span class="item-title">已购产品</span>
                                        <div class="item-value">
                                            <div class="footer">
                                                <!-- <span>订单总金额</span> -->
                                                <span>¥{{order.orderAmount}}</span>
                                            </div>
                                            <div v-for="fare in fareData" v-bind:key="fare.title">
                                                <ul class="list">
                                                    <li>
                                                        <span>{{fare.title}}</span>
                                                        <span>¥{{fare.price}} * {{fare.passCount}}</span>
                                                    </li>
                                                    <li>
                                                        <span>机建+燃油</span>
                                                        <span>¥{{fare.tax}} * {{fare.passCount}}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <ul class="list">
                                                <li>
                                                    <span>航班保险</span>
                                                    <span>¥0</span>
                                                </li>
                                                <li>
                                                    <span>保险优惠减免</span>
                                                    <span>¥0</span>
                                                </li>
                                                <li>
                                                    <span>
                                                        优惠减免
                                                        <i class="iconfont icon-question"></i>
                                                    </span>
                                                    <span>¥0</span>
                                                </li>
                                                <li>
                                                    <span>里程抵扣</span>
                                                    <span>¥0</span>
                                                </li>
                                                <li>
                                                    <span>邮寄费用</span>
                                                    <span>¥0</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div>
                                        <span class="item-title">需扣款项</span>
                                        <span class="item-value">¥{{refundFee}}</span>
                                    </div>
                                </div>
                                <!-- <div class="title">
                                            退票费：
                                            <span
                                                v-loading="refundFeeLoading"
                                                style="color: #d31a1a;"
                                            >¥{{applyRefundAmount}}</span>
                                            手续费：
                                            <span
                                                v-loading="refundFeeLoading"
                                                style="color: #d31a1a;"
                                            >¥{{refundFee}}</span>
                                </div>-->
                            </div>
                        </div>
                        <!-- <div style="width: 900px;text-align: center;">
                                    <el-button
                                        type="danger"
                                        v-loading="refundLoading"
                                        v-if="order.orderStatus=='IW'"
                                        style="width:300px;margin:10px 0;"
                                        @click="refundTicketHandler"
                                    >退&nbsp;票</el-button>
                        </div>-->

                        <div style="width: 900px;text-align: center;">
                            <el-button
                                type="danger"
                                v-loading="refundLoading"
                                style="width:300px;margin:10px 0;border-radius:5px;"
                                @click="refundTicketHandler"
                            >确认退票</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import Vue from "vue";
import moment from "moment";
import {
  orderList,
  orderCancel,
  orderDetail,
  refundFeeAction,
  refundAction
} from "@/api/newsapi";
import { CURRENT_USER } from "@/store/mutation-types";
import FeePanel from "../components/Book2FeePanel";
export default {
  props: ["visible", "orderNo", "fareData"],
  components: { FeePanel },
  data() {
    return {
      //   dialogVisible: this.visible,
      input: "",
      order: {},
      refundLoading: false,
      applyRefundAmount: 0.0,
      refundFee: 0.0,
      refundFeeLoading: false,
      orderSearchFilter: {
        orderStatus: [],
        createTimeStart: "",
        createTimeEnd: "",
        currentPage: 0,
        pageSize: 20,
        sortType: "desc"
      },
      leftPannelSelected: "orderlist",
      pageData: { pageSize: 20, page: 1, totalPage: 5, total: 100 },
      tableData: [],
      //   orderNo: "",
      selectSegCount: 0,
      selectPassCount: 0,
      refundFeeRequest: { refundType: "UNCONSTRAINT", refundReason: "" },
      options: [
        { key: "WP", label: "等待支付", value: "WP" },
        { key: "PF", label: "支付失败", value: "PF" },
        { key: "PT", label: "待出票", value: "PT" },
        { key: "II", label: "出票中", value: "II" },
        { key: "IW", label: "出票完成", value: "IW" },
        { key: "DF", label: "出票失败", value: "DF" },
        { key: "XX", label: "订单取消", value: "XX" }
      ]
    };
  },
  watch: {
    orderNo: function(newVal) {
      console.log(newVal);
      this.orderDetailHandler();
    }
  },
  filters: {
    fromNow(date) {
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    fromNowShort(date) {
      return moment(date).format("YYYY-MM-DD HH:mm");
    },
    formatActualStatus(actualStatus) {
      var statusDesc = "";
      switch (actualStatus) {
        case "NEW":
          statusDesc = "未出票";
          break;
        case "OPEN_FOR_USE":
          statusDesc = "已出票";
          break;
        case "REFUND_COMMITTED":
          statusDesc = "提交成功待审核";
          break;
        case "REFUNDING":
          statusDesc = "退票中";
          break;
        case "PORTION_REFUNDING":
          statusDesc = "部分退票中";
          break;
        case "REFUNDED":
          statusDesc = "已退票";
          break;
        case "PORTION_REFUNDED":
          statusDesc = "已部分退票";
          break;
        case "USED":
          statusDesc = "已使用";
          break;
        case "INVALID":
          statusDesc = "已作废";
          break;
      }

      return statusDesc;
    }
  },
  mounted() {
    this.loadOrderList(1);
  },
  methods: {
    segAndPassChange() {
      console.log(this.order);
      this.refundFeeRequest.orderNo = this.orderNo;
      this.refundFeeRequest.refundSource = "INTERFACE";
      this.refundFeeRequest.contactName = this.order.contact.name;
      this.refundFeeRequest.contactMobile = this.order.contact.mobile;
      this.refundFeeRequest.segments = [];
      this.refundFeeRequest.passengers = [];
      this.selectSegCount = 0;
      this.selectPassCount = 0;
      for (
        var segIndex = 0;
        segIndex < this.order.segments.length;
        segIndex++
      ) {
        if (this.order.segments[segIndex].checked) {
          this.selectSegCount++;
          console.log("航段数：" + this.selectSegCount);
          this.refundFeeRequest.segments.push({
            depAirport: this.order.segments[segIndex].depAirport,
            arrAirport: this.order.segments[segIndex].arrAirport
          });
        }
      }

      for (
        var passIndex = 0;
        passIndex < this.order.passengers.length;
        passIndex++
      ) {
        if (this.order.passengers[passIndex].checked) {
          this.selectPassCount++;
          console.log("乘机人数：" + this.selectPassCount);
          this.refundFeeRequest.passengers.push({
            name: this.order.passengers[passIndex].name,
            certNo: this.order.passengers[passIndex].certNo,
            certType: this.order.passengers[passIndex].certType
          });
        }
      }

      if (
        this.selectSegCount > 0 &&
        this.selectPassCount > 0 &&
        this.refundFeeRequest.refundType.length > 0
      ) {
        if (this.refundFeeRequest.refundReason.length <= 0) {
          //this.refundFeeRequest.refundReason="请输入退票原因";
        }
        this.refundTicketFeeHandler();
      }
      console.log(this.refundFeeRequest);
    },
    selectPannel(pannelId) {
      document.getElementById("li_passlist").setAttribute("class", "");
      document.getElementById("li_order").setAttribute("class", "");
      document.getElementById("li_changepwd").setAttribute("class", "");
      document.getElementById("li_userinfo").setAttribute("class", "");
      document.getElementById(pannelId).setAttribute("class", "active");
    },
    refundTicketFeeHandler() {
      refundFeeAction({ t: this.refundFeeRequest }).then(res => {
        if (res.success) {
          this.applyRefundAmount = res.data.applyRefundAmount;
          this.refundFee = res.data.refundFee;
        }
      });
    },
    refundTicketHandler() {
      if (this.selectSegCount == 0 || this.selectPassCount == 0) {
        this.$message.error("请先选择退票的行程及乘机人信息，在进行退票");
        return;
      }

      if (this.refundFeeRequest.refundReason.length <= 0) {
        this.$message.error("请输入退票原因");
        return;
      }

      this.refundLoading = true;
      console.log("退票信息：", this.refundFeeRequest);
      refundAction({ t: this.refundFeeRequest }).then(res => {
        this.refundLoading = false;
        if (res.success) {
          this.applyRefundAmount = res.data.applyRefundAmount;
          this.refundFee = res.data.refundFee;

          if (
            "SUBMIT_FAIL,AUDIT_FAIL,AUDIT_ONE_REJECT".indexOf(
              res.data.refundStatus
            ) > -1
          ) {
            this.$message.error(
              "退票提交失败，状态信息：" + res.data.refundStatus
            );
          } else {
            this.$message({
              message: "退票提交成功，等待后台审核",
              type: "success"
            });

            this.$router.push({ path: "/member/orderlist", query: { t: 1 } });
          }
        } else {
          this.$message.error("退票提交失败，错误信息：" + res.errorMessage);
        }
      });
    },
    orderDetailHandler() {
      var request = { t: this.orderNo };
      console.log("request", request);
      orderDetail(request).then(res => {
        this.loading = false;
        if (res.success) {
          console.log(res);
          var orderData = res.data;
          for (
            var segIndex = 0;
            segIndex < orderData.segments.length;
            segIndex++
          ) {
            orderData.segments[segIndex].checked = false;
          }

          for (
            var passIndex = 0;
            passIndex < orderData.passengers.length;
            passIndex++
          ) {
            orderData.passengers[passIndex].checked = false;
          }

          this.order = res.data;
        } else {
          this.flights = [];
        }
      });
    },

    gotoCancelOrder(orderNo) {
      this.$confirm("当前座位紧张，请确认是否立即取消此订单？", "温馨提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "立即取消",
        cancelButtonText: "在等一等",
        customClass: "m-message-box"
      }).then(() => {
        this.cancelOrderHandler(orderNo);
      });
    },
    cancelOrderHandler(orderNo) {
      orderCancel({ t: orderNo }).then(res => {
        if (res.success) {
          this.loadOrderList(1);
        }
      });
    },
    loadOrderList(page) {
      console.log(page);
      orderList({ t: this.orderSearchFilter }).then(res => {
        if (res.success) {
          this.pageData.totalPage = res.data.totalPage;
          this.tableData = res.data.data;
          this.pageData.total = res.data.count;
          console.log(res);
        } else {
          this.flights = [];
        }
      });
    },
    handleClose(done) {
      this.$emit("close");
    }
  }
};
</script>
<style scoped lang='less'>
.checkin {
  margin-top: 20px;
  margin-bottom: 20px;
}
.left_pannel {
  height: 500px;
  background-color: #f5f5f5;
  overflow: hidden;
}
.right_pannel {
  position: relative;
  background-color: #ffffff;
  /* border: 1px solid #ededed; */
}
.col_menu b {
  font-size: 24px;
  margin: 20px 20px 0;
  color: #333;
  display: block;
  font-weight: normal;
  border-bottom: 1px solid #f1f1f1;
}
.col_menu ul {
  margin-bottom: 20px;
}
ul,
ol,
dl,
dd,
dt {
  margin: 0;
  padding: 0;
  display: block;
}
.col_menu li {
  margin: 10px 0;
  position: relative;
}
.col_menu li.current {
  background: none;
}
.col_menu li.current a:link,
.col_menu li.current a:visited,
.col_menu li.current a:active {
  border-left: 2px #ff6600 solid;
  color: #666;
  font-weight: bold;
}
.col_menu li a:link,
.col_menu li a:visited,
.col_menu li a:active {
  display: block;
  line-height: 30px;
  padding: 0 55px 0 20px;
}
a:link,
a:visited,
a:active {
  color: #666;
  text-decoration: none;
}

a {
  color: #337ab7;
  text-decoration: none;
}
.warm-prompt {
  padding: 40px 50px 80px;
}
.warm-prompt p {
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: #9d9d9d;
  text-align: left;
}

.order-search .service-title {
  font-size: 18px;
  font-weight: 700;
  line-height: 55px;
  color: #4d4e4e;
  text-align: center;
  border-bottom: 1px solid #eae7e7;
}
.order-search .service-prompt {
  padding-top: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.8;
  color: #9d9d9d;
  text-align: center;
}
.order-search .service-forms > li {
  margin-top: 20px;
}

.form-group .form-label {
  width: 100px;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  line-height: 35px;
  color: #4d4e4e;
  text-align: right;
}
.checkin-links.query {
  margin: 24px 35px 24px 75px !important;
}

.agree-protocol {
  margin: 24px 0;
  padding-left: 24px;
  font-size: 0;
}
.link.link-sm {
  height: 18px;
  font-size: 12px;
  line-height: 18px;
}

.link {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2c6e8b;
  text-align: left;
}

.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}
.popcontext .tab {
  z-index: 1000;
  width: 240px;
}
.popcontext .tab li {
  height: 60px;
  line-height: 60px;
  /* border-top: 1px solid #4e617f; */
  background-color: #f5f5f5;
  color: #000000;
  font-size: 14px;
  text-align: center;
  transition: all 0.5s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  margin-left: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}

.popcontext .tab li:last-child {
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-bottom-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.popcontext .tab li.active {
  /* border-top: 1px solid #fff; */
  background-color: #d6003a;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}
.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  -webkit-border-radius: 0 5px 5px 0;
  -moz-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}
.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}

.fee-panel-body .footer {
  padding: 0 26px;
  height: 60px;
  font-weight: 700;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fee-panel-body .footer span:nth-child(2) {
  font-size: 18px;
  color: #d70039;
}
h4 {
  height: 34px;
  font-size: 14px;
  font-weight: 700;
  line-height: 34px;
  color: #4d4e4e;
}
.noBefore {
  padding: 16px 0 0 10px;
  border-top: 1px solid #bcbcbd;
}
.noBefore::before {
  content: none;
  width: 0 !important;
}
.fee-panel-group ul li {
  height: 26px;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #4d4e4e;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fee-panel-group ul::before {
  position: absolute;
  top: 6px;
  left: 0;
  bottom: 6px;
  width: 2px;
  content: "";
  background-color: #d8d8d8;
}
.fee-panel-group ul {
  position: relative;
  padding-left: 10px;
}
.fee-panel-group > div {
  margin-bottom: 10px;
}
.fee-panel-group {
  padding-left: 10px;
}
.fee-panel__inner {
  // border: 1px solid #eae7e7;
}
.fee-panel-content {
  padding: 16px;
  background-color: #fff;
}
.fee-panel-body {
  max-height: 485px;
  overflow-y: auto;
  background-color: #f8f8f8;
}
.fee-panel-header {
  height: 60px;
  line-height: 60px;
  color: #4d4e4e;
  font-size: 16px;
  font-weight: 700;
  background-color: #f8f8f8;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}
.tips-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.passenger-input-info .el-input,
.passenger-input-info .el-select {
  width: 287px;
}
.no-padding {
  padding: 8px 16px 22px !important;
}
.form-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-title div:first-child {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  color: #b2b2b2;
  text-align: left;
}
.form-title div:nth-child(2) {
  margin: 0 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #9d9d9d;
  text-align: center;
  cursor: pointer;
}
.check-item {
  height: 32px;
  line-height: 32px;
}
.passenger-info {
  margin-bottom: 16px;
  background-color: #fff;
  padding: 8px 0;
  padding-left: 5%;
  line-height: 30px;
  position: relative;
}
.booked-ticket + .booked-ticket {
  margin-top: 20px;
}
.booked-flight-rule {
  width: 15%;
}
.booked-flight-rule span {
  width: 80px;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  color: #e34c4c;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e34c4c;
  border-radius: 2px;
  transition: all, 0.68s;
  display: inline-block;
  margin: 0;
  padding: 0;
  height: 25px;
}
.booked-flight-cabin {
  width: 15%;
}
.booked-flight-cabin span:first-child {
  margin-right: 8px;
}
.booked-flight-arr {
  width: 20%;
}
.booked-flight-arr p:first-child {
  margin-top: 10px;
  height: 25px;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}
.booked-flight-arr p:nth-child(2) {
  height: 20px;
  line-height: 20px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.booked-flight-arr p:last-child {
  height: 17px;
  font-size: 12px;
  line-height: 17px;
}
.booked-flight-duration p:first-child {
  margin-top: 23px;
  height: 17px;
  line-height: 17px;
}
.booked-flight-duration p:nth-child(2) {
  line-height: 15px;
}
.booked-flight-duration p:last-child {
  height: 17px;
  line-height: 17px;
}
.booked-flight-duration {
  width: 15%;
  padding: 0 10px;
  font-size: 12px;
  text-align: center;
}
.booked-flight-dep {
  text-align: right;
  width: 20%;
}
.booked-flight-dep p:first-child {
  margin-top: 10px;
  height: 25px;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}
.booked-flight-dep p:nth-child(2) {
  height: 20px;
  line-height: 20px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.booked-flight-dep p:last-child {
  height: 17px;
  font-size: 12px;
  line-height: 17px;
}
.qucheng {
  margin-left: 4px;
  color: #fff;
}
.booked-ticket-panel > div {
  line-height: 85px;
}
.booked-flight-type {
  color: #fff;
  background-color: #e34c4c;
  border: 1px solid #d31a1a;
  width: 15%;
  text-align: center;
}
.booked-flight-type p:first-child {
  margin-top: 10px;
  height: 20px;
  line-height: 20px;
}
.booked-flight-type p:last-child span {
  font-size: 16px;
  font-weight: 700;
}
.booked-flight-type p:last-child {
  margin-top: 10px;
  height: 22px;
  line-height: 22px;
}
.booked-ticket-panel {
  margin-bottom: 16px;
  background-color: #fff;
  border: 1px solid #eae7e7;
  display: flex;
  height: 85px;
}
.booked-ticket-body {
  padding: 0 18px;
  font-size: 14px;
}
.passenger {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.info-panel {
  width: 756px;
  position: relative;
}
.fee-panel {
  width: 428px;
}
.booked-ticket {
  background: #ffffff;
  /* border: 1px solid #ededed; */
  border-radius: 2px;
}
.booked-title {
  height: 42px;
  box-sizing: border-box;
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-weight: 700;
  color: #4d4e4e;
  font-size: 16px;
}
.research {
  padding: 0;
  width: 90px;
  height: 26px;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  color: #fff;
  text-align: center;
  background-color: #d70039;
  border-color: #d70039;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.next-step {
  width: 100%;
  height: 53px;
  border: 0;
  background-color: #d70039;
  color: #fff;
  font-size: 16px;
  margin: 42px 0 53px;
}
.next-step:active {
  background-color: #d70039;
}
.add-passenger-tips {
  font-size: 14px;
  color: #9d9d9d;
}
.add-passenger-tips span {
  color: orange;
}
.add-wrapper {
  display: flex;
  align-items: center;
}
.add-passenger {
  margin-right: 10px;
  background-color: #d90007;
  width: 163px;
  height: 43px;
  color: #fff;
  border: 0;
  cursor: pointer;
}
.add-passenger:active {
  background-color: #d90007;
  border-color: #d90007;
}
.save-often .el-checkbox__label {
  font-size: 12px;
  color: #bc8b0d !important;
}
.save-often .el-checkbox__input.is-checked .el-checkbox__inner,
.save-often .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #bc8b0d !important;
  border-color: #bc8b0d !important;
}
.save-often .el-checkbox__inner:hover {
  border-color: #bc8b0d !important;
}
.save-often .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #bc8b0d !important;
}
.icon-question {
  font-size: 13px;
}
.form-label {
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  font-weight: 700;
  color: #717070;
}
.passenger-input-info .el-form-item__label {
  padding-bottom: 0;
}
.passenger-input-info .el-form-item {
  margin-bottom: 10px;
}
.passenger-input-info .el-input__inner {
  border-radius: 0 !important;
}
.passenger .el-checkbox__input.is-checked .el-checkbox__inner,
.passenger .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #e34c4c;
  border-color: #e34c4c;
}
.el-checkbox__label {
  padding-left: 5px;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #e34c4c;
}
.el-checkbox__inner:hover {
  border-color: #e34c4c;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #e34c4c;
}
.item-title {
  color: #929a9f;
  font-size: 14px;
  width: 70px;
  display: inline-block;
}
.item-index {
  color: #929a9f;
  font-size: 16px;
  font-style: italic;
}
.item-value {
  color: #515457;
  font-size: 14px;
  display: inline-block;
  li > span:nth-child(1) {
    display: inline-block;
    min-width: 200px;
  }
  li > span:nth-child(2) {
    display: inline-block;
    min-width: 100px;
    text-align: right;
  }
}
.refund-segment {
  display: flex;
  align-items: center;
  position: relative;
  &-check {
    position: absolute;
    right: 20px;
  }
}
.order-pass-item {
  display: flex;
  align-items: center;
  position: relative;
  &-check {
    position: absolute;
    right: 20px;
  }
}
</style>